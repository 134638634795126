<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>课程标签</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="标签名称">
                                <el-input v-model="search.tag_name"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                                   @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                                   @click="issearch = !issearch">显示搜索
                        </el-button>
                        <el-button v-if="is_auth('lesson.tag.issave')" icon="el-icon-plus"
                                   size="medium" type="primary" @click="add_page">添加
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table
                    v-loading="loading"
                    :data="tableData"
                    border
                    size="medium"
                    style="width: 100%">
                <el-table-column
                        prop="tag_name"
                        label="标签名称"
                        width="180">
                    <template slot-scope="scope">
                        <el-tag class="tag_colour-tag" effect="dark" size="mini" :color="scope.row.tag_colour">
                            {{ scope.row.tag_name }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="tag_colour"
                        label="标签颜色"
                        width="180">
                    <template slot-scope="scope">
                        <div class="tag_colour" type="success" :style="get_tag_colour(scope.row.tag_colour)">
                            {{ scope.row.tag_colour }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="describe"
                        label="标签描述"
                >
                </el-table-column>
                <el-table-column
                        prop="we_analyse"
                        label="we分析"
                >
                </el-table-column>
                <el-table-column
                        prop="update_time"
                        label="添加时间"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="create_time"
                        label="修改时间"
                        width="180">
                </el-table-column>
                <el-table-column
                        fixed="right"
                        label="操作"
                        width="150">
                    <template slot-scope="scope">
                        <el-button
                                v-if="is_auth('lesson.tag.issave')"
                                @click="edit_page(scope.row)"
                                size="mini">编辑
                        </el-button>
                        <el-button
                                v-if="is_auth('lesson.tag.isdel')"
                                @click="operation_tip (scope.row.tag_uuid,scope.row.tag_name,'isdel')"
                                type="danger" size="mini">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--编辑-->
            <el-dialog v-loading="loading"
                       v-if="is_auth('lesson.tag.issave')"
                       title="编辑标签"
                       :visible.sync="editPage"
                       width="50%"
                       :close-on-press-escape="false"
                       :close-on-click-modal="false"
            >
                <el-row>
                    <el-form size="medium" ref="form" :label-width="this.env.label_width">
                        <el-col :span="24">
                            <el-form-item label="标签名称">
                                <el-input v-model="info.tag_name" maxlength="5"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="标签颜色">
                                <div class="block">
                                    <el-color-picker v-model="info.tag_colour"></el-color-picker>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="we分析">
                                <el-input v-model="info.we_analyse" maxlength="150"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="标签描述">
                                <el-input v-model="info.describe" type="textarea" maxlength="20" show-word-limit
                                          rows="3"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-form>
                </el-row>
                <el-row style="margin: 10px 0px;">
                    <el-button size="medium" type="primary" @click="save">确 定</el-button>
                    <el-button size="medium" @click="editPage = false">取 消</el-button>
                </el-row>

            </el-dialog>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination
                    @current-change="getlist"
                    :page-size="this.env.pageSize"
                    :pager-count="7"
                    background
                    layout="prev, pager, next, total"
                    :current-page.sync="page"
                    :total="count">
            </el-pagination>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '课程标签',
            issearch: false,
            loading: true,      // 加载状态
            search: {},     // 搜索条件
            count: 0,        // 数据总条数
            tableData: [],// 列表内容
            page: 1,            // 当前页数
            tag_uuid: '',
            info: {}, // 编辑数据
            editPage: false,
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            // 设置搜索条件
            this.search = {
                tag_name: '',
            }
            // 获取数据
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "lesson.tag.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.$message.error(json.message);
                }
            })
        },
        // 操作提示
        operation_tip(tag_uuid = '', tag_name = '', operation = '') {

            let tip = ''
            let options = {}
            // 删除
            if (operation === 'isdel') {
                tip = '删除【' + tag_name + '】？'
                options = {type: 'error'}
            }

            // 弹出二次确认
            this.$confirm(tip, '确认信息', options)
                    .then(() => {
                        this.isoperation(tag_uuid, operation)
                    })
                    .catch(() => {
                    });
        },
        // 操作
        isoperation(tag_uuid = '', operation = '') {
            let postdata = {
                api_name: "lesson.tag." + operation,
                token: this.Tool.get_l_cache('token'),
                tag_uuid: tag_uuid,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 添加页面显示
        add_page() {
            this.tag_uuid = ''
            this.info = {
                tag_name: '',
                we_analyse: '',
                tag_colour: '',
                describe: '',
            }
            this.editPage = true    // 显示页面
        },

        // 编辑显示
        edit_page(row = {}) {
            this.info = row
            this.tag_uuid = row.tag_uuid
            this.editPage = true    // 显示页面
        },
        // 保存
        save() {
            let postdata = {
                api_name: 'lesson.tag.issave',
                token: this.Tool.get_l_cache('token'),
                tag_name: this.info.tag_name,
                we_analyse: this.info.we_analyse,
                tag_colour: this.info.tag_colour,
                tag_uuid: this.tag_uuid,
                describe: this.info.describe
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.editPage = false
                    this.$message({
                        message: '保存成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()
                        }
                    });

                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 获取标签颜色样式
        get_tag_colour(tag_colour) {
            return `border-color:${tag_colour}; color: ${tag_colour};`
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tag_colour {
    height: 28px;
    /*background: #2c3e50;*/
    border: 1px solid;
    text-align: center;
    line-height: 28px;
}

.tag_colour-tag {
    border: 0px;
}
</style>
